.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
  padding: 0 0 0;

}
.ant-input {
  border-radius: 6px;
  border: 0;
  padding: 8px 12px;
  box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgb(0 0 0 / 7%), 0 1px 1.5px 0 rgb(0 0 0 / 5%);
}

.ant-btn {
  border-radius: 6px;
  height: 44px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 6px;
}
