@tailwind base;

@layer base {
  .custom ul {
    @apply list-disc ml-4 mb-1;
  }
  .custom ol {
    @apply list-decimal ml-4 mb-1;
  }
  .custom p {
    @apply break-words;
  }
}

@tailwind components;

@tailwind utilities;
